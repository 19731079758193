
import { walletLogo, LogoType } from '../assets/wallet-logo';
import { WalletInfo } from './info';

export type { LogoType };

interface PlatformOptions {
  useDeeplink?: boolean;
  debug?: boolean;
  preferredOpenLink?: (link: string, target?: string) => void;
}

// const LINK_OPEN_DELAY = 500;

class PlatformManager {
  private debug = false;
  private useDeeplink = false;
  private preferredOpenLink?;
  constructor({
    debug = false,
    preferredOpenLink,
    useDeeplink = false,
  }: PlatformOptions) {
    this.debug = debug;
    this.useDeeplink = useDeeplink;
    this.preferredOpenLink = preferredOpenLink;
  }
  //   openDeepLink(universalLink: string, deeplink: string, target?: string) {
  //     if (this.debug) {
  //       console.debug(
  //         `Platform::openDeepLink universalLink --> ${universalLink}`
  //       );
  //       console.debug(`Platform::openDeepLink deepLink --> ${deeplink}`);
  //     }

  //     if (this.preferredOpenLink) {
  //       this.preferredOpenLink(universalLink, target);
  //       return;
  //     }
  //     if (typeof window !== 'undefined') {
  //       let win: Window | null;
  //       if (this.useDeeplink) {
  //         win = window.open(deeplink, '_blank');
  //       } else {
  //         win = window.open(universalLink, '_blank');
  //       }
  //       setTimeout(() => win?.close?.(), LINK_OPEN_DELAY);
  //     }
  //   }


  // logo 返回一个对象，声明不同的key
  getLogo(type: LogoType = 'square'): string {
    return walletLogo(type);
  }

  getWalletInfo() {
    // return {
    //   name: WalletInfo.name,
    //   homepage: WalletInfo.homepage,
    //   logo: this.getLogo(),
    // };


    return {
      ...{ logo: this.getLogo() }, ...WalletInfo
    };
  }

  getDownloadLinks() {
    return WalletInfo.downloadLinks;
  }

  getDeepLinks() {
    return WalletInfo.deepLinks;
  }
}

export { PlatformManager };
