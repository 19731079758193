import { AbstractAdapter } from './AbstractAdapter';
import { getWaleltProvider, SupportProviderFlag } from '../provider';
import type { TronLinkProvider } from '../provider';
type TronLinkAdapterOptions = {
  isBitkeep?: boolean;
  debug?: boolean
};

class TronLinkAdapter extends AbstractAdapter {
  constructor(
    Options: TronLinkAdapterOptions = {
      isBitkeep: true
    }
  ) {
    super({
      providerFlag: SupportProviderFlag.TRON,
      isBitkeep: Options.isBitkeep,
      debug: Options.debug
    });
  }
  getProvider() {
    return getWaleltProvider<TronLinkProvider>(this.providerFlag) as TronLinkProvider;
  }
}

export { TronLinkAdapter };
