import type { BitKeepGlobal } from './provider';
import * as utils from './utils';

export * from './adapters';
export * from './constant';
export * from './types';
export * from './provider';

export type { BitKeepGlobal }
declare global {
  interface Window {
    addEventListener(eventName: string, fn: Function, options: any): void;
    removeEventListener(eventName: string, fn: Function): void;
    bitkeep: BitKeepGlobal;
    selectedAccount: {
      id: string;
      name: string;
    }
    | undefined;
  }
}

export default utils;
