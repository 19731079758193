import EventEmitter from 'eventemitter3';
import { PlatformManager, LogoType } from '../constant/platform'
import { WalletInfo } from '../constant/info';
import { walletLogo } from '../assets/wallet-logo';
import { getDeepLinks, getDownloadLinks } from '../constant/info';

import {
  BitKeepGlobal,
  getWaleltProvider,
  SupportProviderFlag,
} from '../provider';


type AbstractAdapterOptions = {
  providerFlag: SupportProviderFlag;
  isBitkeep?: boolean;
  debug?: boolean;
};

export class AbstractAdapter extends EventEmitter {
  readonly id: string = WalletInfo.id;
  readonly name: string = WalletInfo.name;
  readonly version: string = WalletInfo.version;

  public isBitkeep: boolean | undefined;
  public providerFlag: SupportProviderFlag;

  private platformManager: PlatformManager;
  constructor(
    Options: AbstractAdapterOptions = {
      providerFlag: SupportProviderFlag.EVM,
      isBitkeep: true,
      debug: false,
    }
  ) {
    super();
    this.providerFlag = Options.providerFlag;
    this.isBitkeep = Options.isBitkeep;

    this.platformManager = new PlatformManager({});
  }
  
  getProvider() {
    return getWaleltProvider<keyof BitKeepGlobal>(this.providerFlag);
  }

  /** only support extension */
  async getBKWalletInfo() {
    return window?.selectedAccount
      ? {
        id: window?.selectedAccount?.id,
        name: window?.selectedAccount?.name,
      }
      : null;
  }


  getDeepLinks() {
    return getDeepLinks()
  }

  getLogo(type: LogoType = 'square'): string {
    return walletLogo(type);
  }

  getLogos() {
    return WalletInfo.logolist
  }

  getWalletInfo() {
    return WalletInfo
  }
  getDownloadLinks() {
    return getDownloadLinks()
  }

}
